@use "copyright";

// Miscellaneous
$page-width: 100%;

// Colors
$primary-color: #000000ff;
$accent-color: #ffffffff;

// Sizing
$button-height: 50px;

// Text
@font-face {
  font-family: "Quicksand";
  src: url("assets/fonts/Quicksand-VariableFont_wght.ttf");
}

$text-fonts: "Quicksand";
$font-size-title: 60px;
$font-size-subtitle0: 40px;
$font-size-subtitle1: 30px;
$font-size-subtitle2: 20px;

$spacer-header-height: 275px;
$spacer0-height: 150px;
$spacer1-height: 125px;
$spacer2-height: 100px;
$spacer3-height: 75px;
$spacer4-height: 50px;
$spacer5-height: 25px;

// Appearance
$border-radius: 25px;
$image-border-radius-multiplier: 15px;
$button-border-width: 2px;

// Animation
$animation-time: .2s;

// padding-percent + content-percent == 100%
$padding-percent: 15%;
$content-percent: 85%;
$column-width: .5;  // Given as a value from 0 to 1.

// Content sizes
$content-padding: 0 calc($column-width * $padding-percent);
$content-width: calc($column-width * $content-percent);

// Mixins
@mixin animateTextButton {
  // Animation
  transition: filter $animation-time, font-weight $animation-time;

  &:hover {
    // Appearance
    filter: drop-shadow(0 0 7px $accent-color);
    font-weight: bold;
  }

  // Dynamically added by TypeScript
  &.selected {
    // Appearance
    filter: drop-shadow(0 0 7px $accent-color);
    font-weight: bold;
  }
}

@mixin animateImageButton($width, $height, $margin-vertical, $margin-horizontal, $growth) {
  // Animation
  transition: filter $animation-time, width $animation-time, height $animation-time, margin $animation-time;

  &:hover {
    // Sizing and positioning
    width: calc($width + $growth);
    height: calc($height + $growth);
    margin: calc($margin-vertical - $growth / 2) calc($margin-horizontal - $growth / 2);

    // Appearance
    filter: drop-shadow(0 0 7px $accent-color);
  }

  &.selected {
    // Sizing and positioning
    width: calc($width + $growth);
    height: calc($height + $growth);
    margin: calc($margin-vertical - $growth / 2) calc($margin-horizontal - $growth / 2);

    // Appearance
    filter: drop-shadow(0 0 7px $accent-color);
  }
}

@mixin text {
  // Text appearance
  color: $accent-color;
  font-family: $text-fonts;
  font-size: x-large;
  text-align: justify;
  text-align-last: left;
}

@mixin button {
  & {  // Animation
    @include animateTextButton;
  } & {  // Sizing and positioning
    margin: auto;
    width: fit-content;
    height: $button-height;
    line-height: $button-height;
    padding: 0 $border-radius;
  } & {  // Content control
    display: block;
  } & { // Appearance
    @include text;
    border-radius: $border-radius;
    text-decoration: none;
    cursor: pointer;
    user-select: none;
  }
}

@mixin title {
  // Sizing and positioning
  width: 100%;

  // Text appearance
  @include text;
  font-size: $font-size-title;

  // Content control
  text-align: center;
  text-align-last: center;
}

@mixin subtitle0 {
  // Includes
  @include title;

  // Sizing and positioning
  width: auto;

  // Text appearance
  font-size: $font-size-subtitle0;
}

@mixin subtitle1 {
  // Includes
  @include title;

  // Sizing and positioning
  width: auto;

  // Text appearance
  font-size: $font-size-subtitle1;
}

@mixin subtitle2 {
  // Includes
  @include title;

  // Sizing and positioning
  width: auto;

  // Text appearance
  font-size: $font-size-subtitle2;
}

@mixin horizontal-base-content {
  // Includes
  @include base-content;

  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin base {
  display: flex;
  flex-flow: column;
  justify-content: center;

  // Content control
  text-align: center;
  margin: auto;

  // Appearance
  background: $primary-color;
}

@mixin base-content {
  // Sizing and positioning
  width: calc(1000px + 10vw);
  max-width: 95vw;
  margin: auto;
}

// Classes
.text {
  // Includes
  @include text;
}

.text-content {
  // Includes
  @include text;

  // Sizing and positioning
  width: calc(500px + 4%);
  max-width: 45%;
  margin: 0 auto;
}

.text-base-content {
  // Includes
  @include base-content;
  @include text;
}

.footnote-centered {
  // Includes
  @include base-content;
  @include text;

  // Appearance
  font-size: small;

  // Sizing and positioning
  display: flex;
  justify-content: center;
}

.button {
  // Includes
  @include button;
}

.title {
  // Includes
  @include title;
}

.subtitle0 {
  // Includes
  @include subtitle0;
}

.subtitle1 {
  // Includes
  @include subtitle1;
}

.subtitle2 {
  // Includes
  @include subtitle2;
}

.spacer0 {
  // Sizing and positioning
  height: $spacer0-height;
}

.spacer1 {
  // Sizing and positioning
  height: $spacer1-height;
}

.spacer2 {
  // Sizing and positioning
  height: $spacer2-height;
}

.spacer3 {
  // Sizing and positioning
  height: $spacer3-height;
}

.spacer4 {
  // Sizing and positioning
  height: $spacer4-height;
}

.spacer5 {
  // Sizing and positioning
  height: $spacer5-height;
}

.spacer-header {
  // Sizing and positioning
  height: $spacer-header-height;
}

.outer-table {
  // Includes
  @include base_content;
  @include text;

  // Sizing and positioning
  width: fit-content;
}

.table-data {
  // Includes
  @include base-content;

  // Content control
  display: inline-flex;
  justify-content: center;

  // Sizing and positioning
  width: 10vw;
}

.list-entry-spaced {
  // Sizing and positioning
  margin-bottom: $spacer5-height;
}:last-child {
   // Sizing and positioning
  margin-bottom: 0;
}

.video {
  // Includes
  @include base-content;

  // Sizing and positioning
  aspect-ratio: calc(16 / 9);
}

body {
  // Includes
  @include base;
}