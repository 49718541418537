@use 'partials/navbar';
@use 'partials/scrollbar';
@use 'partials/site';

.banner {
  // Includes
  @include site.base-content;
}

.image {
  // Includes
  @include site.base-content;

  // Sizing and positioning
  width: fit-content;

  // Appearance
  border-radius: site.$image-border-radius-multiplier;
}