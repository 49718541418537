@use 'site';

// Appearance
$border-radius: 20px;
$border: 3px;

// Sizing
$width: 12px;

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: site.$accent-color site.$primary-color;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: $width;
  height: $width;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: site.$accent-color;
  border-radius: $border-radius;
  border: $border solid site.$primary-color;
}

*::-webkit-scrollbar-corner {
  background-color: transparent;
}