@use 'site';

// Backdrop
$color: rgba(#000000, .6);

// Size and shape
$offset: 20px;
$width: calc(100% - $offset * 2);
$max-expanded-height: calc(75vh - $offset * 2);
$height: site.$button-height;
$max-width: calc(1000px + 10vw);
$min-width: 305px;
$font-size: 20px;
$logo-image-margin: 5px;
$logo-image-height: calc($height - $logo-image-margin * 2);
$logo-image-width: calc($logo-image-height - $logo-image-margin * 2);
$spacer-width: 175px;
$panel-container-max-width: 800px;
$panel-container-min-width: 400px;

// Animation
$animation-time: .3s;

// Tabs
$about-content-count: 3;
$products-content-count: 1;
$resources-content-count: 2;

// Contact Contents
$content-panel-width: 40%;

.navbar {
  // Sizing and positioning
  position: fixed;
  top: $offset;
  left: calc((100% - max(min($width, $max-width), $min-width)) / 2);
  width: $width;
  max-width: $max-width;
  min-width: $min-width;
  height: fit-content;
  min-height: $height;
  max-height: $max-expanded-height;
  z-index: 1;  // Must be put on top of any absolute elements spawned by anything on the page. (Currently only the Dropdown)

  // Appearance
  background: $color;
  backdrop-filter: blur(12px);
  border: transparent solid 2px;
  border-radius: site.$border-radius;

  // Animation
  transition: border-top-color $animation-time,
      border-bottom-color $animation-time,
      border-left-color $animation-time,
      border-right-color $animation-time;

  // Content control
  display: flex;
  flex-direction: column;

  &_panel {
    // Appearance
    height: $height;

    // Content control
    display: flex;
    justify-content: space-between;

    // Contents
    &_logo {
      & { // Animation
        @include site.animateImageButton($logo-image-width, $logo-image-height, $logo-image-margin, site.$border-radius, $logo-image-margin);
      } & {  // Sizing and positioning
        margin: $logo-image-margin site.$border-radius;
        width: $logo-image-width;
        height: $logo-image-height;
      }
    }

    &_container {
      // Sizing and positioning
      max-width: $panel-container-max-width;
      min-width: $panel-container-min-width;
      width: 100%;

      // Content control
      display: flex;
      justify-content: space-between;

      &_button {
        margin: 0;
      }
    }
  }

  &_body {
    // Sizing and positioning
    position: relative;
    height: 0;  // Overwritten in TypeScript.

    // Appearance
    overflow: hidden;

    // Animation
    transition: height $animation-time;

    // Contents
    &_container {
      // Sizing and positioning
      position: absolute;
      bottom: 0;
      width: 400%;
      left: 0;  // Overwritten in TypeScript.

      // Appearance
      overflow: hidden;

      // Animation
      transition: left $animation-time;

      // Content control
      display: inline-flex;
      flex-wrap: nowrap;

      // Contents
      &_entry {
        // Includes
        @include site.base;

        // Content control
        flex-flow: row;
        justify-content: space-evenly;

        // Sizing and positioning
        position: relative;
        bottom: 0;
        margin-bottom: 0;
        width: calc(25% - $offset * 2);
        max-height: calc($max-expanded-height - $height - $offset * 2);
        padding: $offset;

        // Appearance
        overflow: auto;
        background: transparent;

        &_contact {
          // Includes
          @include site.text;

          // Contents
          &_content {
            // Content control
            display: flex;
            justify-content: space-between;
            text-align: left;

            // Contents
            &_subContent {
              // Sizing and positioning
              width: $content-panel-width;

              // Contents
              a {
                // Includes
                @include site.text;
              }
            }
          }
        }
      }
    }

    &_title {
      // Includes
      @include site.title;

      // Override site.title padding
      padding: 0;
    }

    &_content_image {
      $image-width: 300px;
      height: $image-width;
      width: $image-width;
      margin-left: calc((100% - $image-width) / 2);
      margin-right: calc((100% - $image-width) / 2);
      margin-top: 20px;
      border-radius: site.$border-radius;
    }
  }
}

